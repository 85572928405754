import React from 'react'
import {
  CFImage,
  CFView,
  CFText,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import OrderDeliveryButton from './OrderDeliveryButton'
import { hero, heroText, mobileHero, mobileHeroText } from 'images'
import ViewMenuButton from './ViewMenuButton'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView
          h="150vh"
          maxHeight="780px"
          w="100%"
          image={`url(${mobileHero}) center / cover no-repeat`}
          column
          justifyStart
          alignCenter
        >
          <CFImage
            src={mobileHeroText}
            w="100%"
            maxWidth="220px"
            alt="About"
            mt="20px"
          />
          <CFView w="100%" column justifyStart alignCenter>
            <CFView pulsate mt="25px" mb="15px" textCenter>
              <OrderPickupButton />
            </CFView>
            <CFView textCenter>
              <OrderDeliveryButton />
            </CFView>
          </CFView>
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView
          h="100vh"
          maxHeight="650px"
          w="100%"
          image={`url(${hero}) top / cover no-repeat`}
          zIndex={90}
          column
          justifyBetween
          alignCenter
        >
          <Header />
          <CFView column center bg="rgba(0,0,0,0.7)" ph="100px" pv="5px">
            <CFView bold white style={{ fontSize: 28 }}>
              Welcome To Yoko Sushi!
            </CFView>
            <CFView row center mt="5px">
              <CFView ml="30px" pulsate>
                <OrderPickupButton />
              </CFView>
              <CFView ml="30px">
                <OrderDeliveryButton />
              </CFView>
            </CFView>
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
